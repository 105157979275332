.panel {
    color: black;
    width: 90dvw;
    max-height: 90dvh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2em !important;
}
.panel p{
    color: gray;
    font-family: 'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 1rem;
}
.flexrow {
    display: flex;
    flex-direction: row;
}

.textinfo {
    flex: 1;
}
.table{
    margin: 20px 0;
    width: 100%;
    overflow-x: scroll;
}