
.node-content{
      display: flex;
      width: 100%;
      justify-content: space-between;
}

.react-flow__node-default {
      font-size: 0.875rem;
      background: #eee;
      border:none;
      border-radius: 4px;
      text-align: left; /* Center text horizontally */
      width: auto;
      box-shadow:#969696 0px 1px 2px 0px;
      
    }
    
    /* CSS class for the label */
    .node-label {
      margin-right: 10px;
      overflow: hidden; /* Ensuring text doesn't overflow */
      white-space: nowrap; /* Keep the label on a single line */
      text-overflow: ellipsis; /* Add ellipsis for overflow text */
      filter: grayscale(1) contrast(999) invert(1)
    }
    
    /* New CSS class for the delete button */
    .node-delete-btn {
      cursor: pointer;
      color:#555;
      z-index: 10;
      align-items: center;
      font-size: 8px; /* Adjust if necessary for better visual */
      user-select: none;
      border: none;
      padding: 2px;
      background-color: transparent;
    }
    .node-delete-btn i{
      text-align: right;
      margin: 0;
      
    }
    .node-delete-menu{
      display: block;
      background-color: #fff;
      box-shadow: 0 0 6px rgba(0,0,0,0.3);
      width: 80px;
      translate: -2px -5px;
      border-radius: 4px;
    }
    .node-delete-menu button{
      width: 100%;
      border: none;
      background-color: #fff;
      text-align: left;
      margin: 5px 0;
      color: #555;
      cursor: pointer;
    }
    .accordion-content{
  margin-bottom: 14px;
}

#ramInGB .menu {
  max-height: 200px;
}

#cpu .menu {
  max-height: 200px;
}

.upload-area {
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%; /* Ensures the div takes full width of its container */
  height: 100%; /* Set a fixed height */
  padding: 0.67857143em 1em; /* Match the default padding of Form.Input */
  font-size: 1em; /* Match the default font-size of Form.Input */
  line-height: 1.21428571em; /* Match the default line-height of Form.Input */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border-radius: 0.28571429rem; /* Match the default border-radius of Form.Input */
  color: rgba(0, 0, 0, 0.87); /* Match the default text color */
  background: #fff; /* Match the default background color */
  border-color: rgba(34, 36, 38, 0.15); /* Match the default border color */
  transition: box-shadow 0.1s ease, border-color 0.1s ease; /* Match the default transition */
  overflow: hidden; /* Hide overflow content */
  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Display ellipsis for overflow text */
}

.upload-area:focus,
.upload-area:hover,
.upload-area.dragging {
  border-color: #2185d0; /* Highlight border color when dragging or on hover/focus */
}