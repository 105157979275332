.dndflow {
  position: relative;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 60px);
  width: 100%;
}

.tool-aside {
  border: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
  overflow-y: scroll;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  /* height: 20px; */
  padding: 4px;
  /* border: 1px solid #1a192b; */
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  box-shadow:#969696 0px 1px 2px 0px;
}
.dndnode p{
  filter: grayscale(1) contrast(999) invert(1);
  margin: auto;
  text-align: center;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  position: relative;
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}
.edit-bar-title{
  font-size: 20px;
  margin: 10px;
  text-align: center;
  margin-top: 0; 
  margin-left: 0; 
  margin-right: 0; 
  width: 100%;
  
}
.edit-bar-form{
  max-height: 70vh; 
  overflow-y: scroll;
  padding-bottom: 36px;
  padding-left: 16px;
  padding-right: 16px;
}

.save-btn{
  width: 100%;
  height: 30px;
}
.group-name{
  margin-top: 20px;
  margin-bottom: 5px;
}


.head-menu{
  display: flex;
  padding: 12px;
  justify-content: space-between;
  height: 60px;
  border-bottom:1px lightgray solid ;
  width: 100%;
}
.head-left a{
  text-align: left;
  width: 200px;
  display: flex;
  color: black;
}
.head-left img{
  width: 36px;
}
.head-right{
  text-align: right;
}

.job-bar-form{
  width: 100%;
  margin: auto;
  margin-top: 10px;
}
.result-aside{
  border: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  overflow-y: scroll;
  background: #fcfcfc;
}
.submit-button{
  position: absolute;
  bottom: 44px;
  right: 10px;
}
.job-bar-form label{
  /* font-weight: 700; */
  font-size: 13px;
  display: block;
  min-height: 38px;
  margin-bottom: 1rem;
}
.job-bar-form h1{
  min-height: 38px;
  margin-bottom: 2rem;
}
.job-bar-form a{
  font-size: 13px;
  display: block;
  min-height: 38px;
  margin-bottom: 1rem;
  margin-right: 5px;
  font-weight: 700;
  text-decoration: underline;
  color:darkcyan
}
.file input{
  display: none;
}
.ui.table thead th{
background-color: #000;
color:white
}

@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable) thead {
    background-color: #000;
  }

  .ui.table:not(.unstackable) tr > th {
    text-align: left !important;
  }

  .ui.table:not(.unstackable) tr > td {
    text-align: left !important;
  }
}

.table-cell{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 798px) {
  .dndflow {
    flex-direction: row;
  }

  .tool-aside {
    width: 30%;
    max-width: 250px;
  }
  .result-aside{
    width: 20%;
    /* max-width: 450px; */
  }
  .sider-resizer {
    position: absolute;
    width: 6px;
    top: 0;
    bottom: 0;
    cursor: col-resize;
  }
  .resize-mask {
    background: rgba(0, 0, 0, 0);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: col-resize;
  }
}
.toggle-container {
  position: relative;
  display: inline-block;
  width: 60px; /* Width of the entire toggle */
  height: 30px; /* Height of the entire toggle */
  user-select: none;
}

.toggle-checkbox {
  display: none; /* Hide the default checkbox */
}

.toggle-switch {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white; /* Background of the switch for inactive state */
  border-radius: 15px; /* Rounded corners for the switch */
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center; /* Center the circle and text vertically */
  justify-content: space-between; /* Distribute children (text and circle) evenly */
}

.toggle-switch:before {
  content: '';
  position: absolute;
  width: 26px; /* Width of the circle */
  height: 26px; /* Height of the circle */
  background-color: #000; /* Black circle color */
  border-radius: 50%;
  transition: left 0.2s; /* Smooth transition for the circle */
  z-index: 2;
}

/* Position the circle on the left when inactive */
.toggle-checkbox:not(:checked) + .toggle-switch:before {
  left: 2px;
}

/* Position the circle on the right when active */
.toggle-checkbox:checked + .toggle-switch:before {
  left: calc(100% - 28px); /* Move circle to the right */
}

.toggle-text {
  position: absolute;
  color: black; /* White text color */
  font-size: 12px; /* Adjust font size as needed */
  font-weight: bold; /* Make the text bold */
  z-index: 1;
  transition: opacity 0.2s;
}

/* When the toggle is inactive, position the text slightly to the left of the right edge */
.toggle-checkbox:not(:checked) + .toggle-switch .toggle-text {
  right: 10px; /* Increase padding from the right edge */
  left: auto;
}

/* When the toggle is active, position the text slightly to the right of the left edge */
.toggle-checkbox:checked + .toggle-switch .toggle-text {
  left: 10px; /* Increase padding from the left edge */
  right: auto;
}

.ui.divider{
  margin: 5px 0 5px 0;
  font-size: 0.875rem;
  color:rgba(191,191,191,.87);
}

.ui.active.dimmer {
  opacity: 0.7;
}