.messageBoard {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 16px;
    z-index: 1000;
    min-width: 300px;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
}

.message {
    box-shadow: 0 0 0 1px rgba(34,36,38,.22) inset,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15) !important;
    padding-right: 32px !important;
    margin: 0.5em 0 !important;
}