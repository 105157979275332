
html, body, #root {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      font-family: sans-serif;
    }
    