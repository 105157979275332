.edgebutton {
  width: 20px;
  height: 20px;
  background: white;
  cursor: pointer;
  border-radius: 50%;
  font-size: 8px;
  color:#555;
  align-items: center;
  border: none;
}
