.dashboardPane {
    width: 66.7vw;
    margin: 20px auto;
}

@media only screen and (max-width: 992px) {
    .dashboardPane {
        width: 95vw;
        margin: 10px auto;
    }
} 
