.mobile-igv-container {
    display: none;
}
.igv-container {
    display: block;
    max-width: 90%;
    min-width: 75rem;
}
@media screen and (max-width: 959px) {
    .mobile-igv-container {
        width: 90%;
        line-height: 1.2rem;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .igv-container {
        display: none;
    }
}